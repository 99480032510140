import { ChangeDetectorRef, inject, Injectable } from '@angular/core';
import { GetRegisterTokenUseCase } from 'app/core/usecases/auth/get-register-token.usecase';
import { CurrentUserStep } from 'app/presentation/shared/components/shared-stepper-indicator/interfaces';
import { LocalStorageService } from 'app/presentation/shared/services/local-storage.service';
import { UserRegistrationStepUtility } from 'app/presentation/shared/utilities/user-registration-step.utility';
import { Subject } from 'rxjs';
import { BasePresenter, ViewEventHandler } from 'src/app/presentation/base/base.presenter';
import { SignUpSideEffect } from './signup.sideEffects';
import { SignUpViewEvents } from './signup.viewEvents';
import { SignUpViewState } from './signup.viewState';

@Injectable()
export class SignUpPresenter extends BasePresenter<
  SignUpViewState,
  SignUpViewEvents,
  SignUpSideEffect
> {
  private _getRegisterTokenUseCase = inject(GetRegisterTokenUseCase);

  private _changeDetectorRef = inject(ChangeDetectorRef);

  private _userRegistrationStepUtility = inject(UserRegistrationStepUtility);

  private _localStorageService = inject(LocalStorageService);

  protected defaultViewState(): SignUpViewState {
    return {
      currentRegisterJWTToken: '',
      currentUserStep$: new Subject<CurrentUserStep>(),
      allowedUserSteps: ['signupLanding', 'signupOtp', 'signupDetails'],
      isUserLoggingIn: false,
    };
  }

  protected viewEvents: ViewEventHandler<SignUpViewEvents> = {
    Init: this._optionallyResolveCurrentRegisterUserToken,
  };

  private _showEmailAccessNotAvailable(): void {
    const user = this._localStorageService.getUser();
    this.viewState.isUserLoggingIn = user?.actualVerificationState?.merchantDataVerified;
    this._changeDetectorRef.detectChanges();
  }

  private _optionallyResolveCurrentRegisterUserToken(): void {
    this._getRegisterTokenUseCase.execute().subscribe({
      next: (token) => {
        this.set('currentRegisterJWTToken', token);
        this._showEmailAccessNotAvailable();
        this._changeDetectorRef.detectChanges();
        this._doResolveCurrentUserStep(token);
      },
    });
  }

  private _doResolveCurrentUserStep(jwtToken: string): void {
    this.viewState.currentUserStep$.next(
      this._userRegistrationStepUtility.currentUserStep(jwtToken),
    );
  }
}
